import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function RoAntiScalantKit() {
  return (
    <div className="section-full  p-t80 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
      <div className="container">
        {" "}
        <div className="my-5">
          <h5 className="m-t80"
              style={{
                fontWeight: "460",
                fontSize: 22,
              }}>Ro Anti-Scalant & De-Scalant</h5>
          <div className="py-5">
            <p
              className="fs-6 m-t10"
              style={{
                fontWeight: "400",
              }}
            >
              COMET range of cleaning chemical used in semi permeable membrane
              used in Reverse Osmosis Plant for non potable purpose. The
              products include organic cleaning, inorganic cleaning and bio
              cleaning.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              {" "}
              COMET formulations for prevention of scale formation
              in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              R.O.Membrane for both regular and high silica range.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoAntiScalantKit;
