import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function PrereatmentPage() {
  return (
    <div className="section-full p-t40 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg-map.png"
    //   )})`,
    // }}
    >
      <div className="container">
        {" "}
        <div className="my-3">
          <div className="">
            {/* <h5 className="card-title">Special title treatment</h5> */}
            {/* <div className="card my-3"> */}
            {/* <div className="card-body"> */}
            {/* <span className="text-info display-block title-second text-uppercase font-20 font-weight-600 display-block m-b5">
              <i> Parer Slimicides </i>
            </span> */}
            <p className="text-dark  fs-5">Coming Soon...</p>
            <p className="p-t150"></p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrereatmentPage;
