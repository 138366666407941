import FastImage from "../../../globals/elements/fastimg";
import { route } from "../../../globals/constants";
// import { route } from "../../../../globals/constants";
import SectionHome1Plans from "../../sections/home/home1/section-home1-plans";
import SectionHome1Team from "../../sections/home/home1/section-home1-team";
import SectionHome2Video from "../../sections/home/home2/section-home2-video";
import SectionHome3WhatWeDo from "../../sections/home/home3/section-home3-what-we-do";
import SectionHome3WhyChooseUs from "../../sections/home/home3/section-home3-why-choose-us";
import { publicUrlFor } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function AboutUsPage() {
  return (
    <>
      {/* Our Mission */}
      {/* <SectionHome3WhatWeDo /> */}

      {/* Why Choose us */}
      {/* <SectionHome3WhyChooseUs /> */}

      {/* Our Services */}
      <div
        className="section-full bg-white p-t80 bg-center bg-full-height bg-no-repeat"
        // style={{
        //   backgroundImage: `url(${publicUrlFor(
        //     "images/background/bg-map.png"
        //   )})`,
        // }}
      >
        <div className="container ">
          {/* <div className="mt-box sx-what-wedo"> */}

          <div className="my-5">
            {/* <span className="text-info card-header display-block title-second text-uppercase font-20 font-weight-600 display-block m-b15">
             about us
            </span> */}
            <div className="">
              {/* <p
                className="justify-content-center fs-6"
                style={{
                  fontWeight: "400",
                }}
              >
                We wish all our customers a &ldquo;
                <strong>Successful New</strong>
                <strong>Year</strong>&rdquo;. We are proud to introduce
                &ldquo;DewbornTM e-Zine&rdquo;which will bring us more close to
                you. DewbornTM e-Zine will focus on creating transparency and
                awareness, in the field of water treatment,which would help us
                to
                <strong>&ldquo;Serve You Better&rdquo;</strong>.
              </p> */}
              <p
                className="justify-content-center fs-6"
                style={{
                  fontWeight: "400",
                }}
              >
                Comet Groups is the leading global provider of integrated water
                treatment and process improvement services, chemicals and
                equipment programs for a variety of industrial and institutional
                customers. Our products and services are used in water treatment
                applications to prevent corrosion, contamination and the buildup
                of harmful deposits, or in production processes to enhance
                process efficiency and improve our customers&rsquo; end products
              </p>
              <p
                className="justify-content-center fs-6"
                style={{
                  fontWeight: "400",
                }}
              >
                During the past century, Comet Groups has grown from a fledgling
                water treatment business to a leading provider of integrated
                water treatment and process improvement services, chemicals and
                equipment programs for industrial and institutional
                applications. We do this by focusing on our core markets of
                water treatment and process chemicals and on our corporate
                strengths - global presence, on-site expertise, and innovative
                products and services.
              </p>
              {/* <NavLink to={route.pages.ABOUT} className="site-button">
                Read more
              </NavLink> */}
            </div>
            <br />
            <p className="text-warning text-uppercase text-center m-t70 m-b30 fs-5">
              We innovate water treatment solutions with visionary design
              principles.
            </p>
            <p
              className="text-center "
              style={{ fontSize: 32, fontWeight: 500 }}
            >
              Advanced engineering and innovative planning tailored for water
              <br />{" "}
            </p>
            <p
              className="text-center m-b20 "
              style={{ fontSize: 32, fontWeight: 500 }}
            >
              treatment solutions.
            </p>{" "}
            <br />
            <br />
            <p
              className="ms-6 d-block text-center"
              style={{
                fontWeight: "400",
                whiteSpace: 28,
                fontSize: 18,
              }}
            >
              For over two decades, Comet Group has been renowned globally for
              delivering exceptional water treatment solutions, exceeding client
              expectations.
            </p>
            <div className="section-full p-t30">
              <div className="container">
                <div className="row m-t80 ">
                  <div className="col-lg-6 col-md-6">
                    {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
                    {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
                    <FastImage
                      src="media/images/image-24.jpg"
                      width={500}
                      height={15}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="sx-shipment-wrap">
                      <div className="mt-box m-b30">
                        <div className="row-container">
                          <h2 className="display-block fs-3 ">
                            We consistently deliver exceptional and sustainable
                            water treatment solutions.
                          </h2>
                          <div className="mt-separator-outer m-b30">
                            <div className="mt-separator bg-warning  m-t25 m-b30" />
                          </div>
                        </div>
                        <p
                          className="m-b15"
                          style={{
                            fontWeight: "400",
                            whiteSpace: 28,
                            fontSize: 17,
                          }}
                        >
                          We collaborate to enhance quality of life by
                          harmonizing built and natural assets, from urban
                          centers to environmental conservation.
                        </p>

                        <div className="text-center p-tb20">
                          <NavLink
                            className="mfp-link"
                            to={route.services.SERVICES}
                            // title="wish list"
                          >
                            <button
                              className="site-button icon-arrow bg-warning"
                              type="button"
                            >
                              Our Services&nbsp;&nbsp;&nbsp;
                              <b className="fa fa-arrow-right" />
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-1 col-md-6">
            </div> */}
                    {/* </div>{" "} */}
                    {/* </div> */}
                  </div>{" "}
                  {/* <div className="section-content m-b30">
            <div className="row">
              <div className="col-md-4 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                    <i className="iconmoon-smartphone-1" />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">Phone number</h4>
                    <p>+91-079-27449045, +91-079-27437067</p>
                    <p>+91-98240 40260, +91-98244 97153</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                    <i className="iconmoon-email" />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">Email address</h4>
                    <p>info@cometgroup.co.in</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                    <i className="iconmoon-travel" />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">Address info</h4>
                    <p>
                      A 1/21, Nirman Complex, R.C Technical Road
                      Ghatlodia,Ahmedabad
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <table
              width="100%"
              align="center"
              border="0"
              cellpadding="0"
              cellspacing="0"
            >
              <tr>
                <td class="linktext">
                 
                </td> */}
      {/* <div className="overlay-main bg-light opacity-09" /> */}
      {/*  <div className="bg-changer">
                    <div className=" section-bg active" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg1.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg2.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg3.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg4.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg5.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg6.jpg")})` }} />
                </div>
                <div className="container"> */}
      {/* TITLE START*/}
      {/* <div className="section-head text-center text-white">
                        <h2 data-title="Services">Our Services </h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div> */}
      {/* TITLE END*/}
      {/* <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-4 col-md-6 m-b30">
                                <div className="mt-icon-box-wraper center  p-a30 bgcall-block  bdr-1 bdr-solid bdr-primary hover-shadow">
                                    <div className="mt-icon-box-sm radius bdr-2 bdr-solid bdr-white m-b20">
                                        <span className="icon-cell  site-text-primary"><i className="flaticon-transport-4" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="mt-tilte  m-b15 site-text-primary">Cargo Transportation</h4>
                                        <p className="text-white">Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6  m-b30">
                                <div className="mt-icon-box-wraper  center  p-a30 bgcall-block  bdr-1 bdr-solid bdr-primary  hover-shadow">
                                    <div className="mt-icon-box-sm radius bdr-2 bdr-solid bdr-white m-b20">
                                        <span className="icon-cell  site-text-primary"><i className="flaticon-truck" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="mt-tilte  m-b15 site-text-primary">Ground Shipping</h4>
                                        <p className="text-white">Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6   m-b30">
                                <div className="mt-icon-box-wraper center p-a30 bgcall-block  bdr-1 bdr-solid bdr-primary hover-shadow ">
                                    <div className="mt-icon-box-sm radius bdr-2 bdr-solid bdr-white m-b20">
                                        <span className="icon-cell  site-text-primary"><i className="flaticon-airplane" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="mt-tilte  m-b15 site-text-primary">Air Freight</h4>
                                        <p className="text-white">Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6  m-b30">
                                <div className="mt-icon-box-wraper center  p-a30 bgcall-block  bdr-1 bdr-solid bdr-primary hover-shadow ">
                                    <div className="mt-icon-box-sm radius bdr-2 bdr-solid bdr-white m-b20">
                                        <span className="icon-cell  site-text-primary"><i className="flaticon-ship-1" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="mt-tilte  m-b15 site-text-primary">Ocean Freight</h4>
                                        <p className="text-white">Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6    m-b30">
                                <div className="mt-icon-box-wraper center  p-a30 bgcall-block  bdr-1 bdr-solid bdr-primary hover-shadow">
                                    <div className="mt-icon-box-sm radius bdr-2 bdr-solid bdr-white m-b20">
                                        <span className="icon-cell  site-text-primary"><i className="flaticon-forklift-1" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="mt-tilte  m-b15 site-text-primary">Packaging &amp; Storage</h4>
                                        <p className="text-white">Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6   m-b30">
                                <div className="mt-icon-box-wraper center p-a30 bgcall-block  bdr-1 bdr-solid bdr-primary hover-shadow">
                                    <div className="mt-icon-box-sm radius bdr-2 bdr-solid bdr-white m-b20">
                                        <span className="icon-cell  site-text-primary"><i className="flaticon-transport-6" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <h4 className="mt-tilte  m-b15 site-text-primary">Road Freight</h4>
                                        <p className="text-white">Vitae adipiscing turpis. Aenean ligula nibh, molestie id viverra.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
      {/* </tr>
            </table> */}
      {/* </div> */}
      {/* </div>
      </div> */}
      {/* </div> */}
      {/* Our Team */}
      {/* <SectionHome1Team /> */}

      {/*Video Section */}
      {/* <SectionHome2Video /> */}

      {/* OUR PLANS  */}
      {/* <SectionHome1Plans /> */}
    </>
  );
}
export default AboutUsPage;
