import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function TestKits() {
  return (
    <div className="section-full p-t80 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
      <div className="container">
        {" "}
        <div className="my-5">
          <div className="">
            {/* <h5 className="card-title">Special title treatment</h5> */}
            <td class="linktext">
              <p
                className="fs-6 m-t50"
                style={{
                  fontWeight: "400",
                }}
              >
                Realising that monitoring of boiler feed water, and boiler blow
                down water, cooling water is very important to achieve the
                purpose of treatment chemical. It has been specially designed to
                test the water in the field.
              </p>

              <strong
                style={{
                  fontWeight: "460",
                  fontSize: 22,
                }}
              >
                Feature
              </strong>
              <br />
              <p
                className="fs-6 m-t20"
                style={{
                  fontWeight: "400",
                }}
              >
                {" "}
                Easy, quick analysis of parameters such as hardness, alkalinity,
                phosphate, sulphate, chlorides, silica, TDS, pH.
              </p>
            </td>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestKits;
