import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function ChemicalPage() {
  return (
    <div>
      <div className="section-full  p-t30 p-b50"
    //   style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
        <div className="container">
          <div className="my-5">
            <h3 className=" site-text-primary fs-5"
              style={{
                fontWeight: "400",
              }}>
              For the chemical treatment of water a great variety of chemicals
              can be applied. Below, the different types of water treatment
              chemicals are summed up.
            </h3>
          </div>

          <div className="p-2">
            <div className="row container">
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Algaecides">Algaecides</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Antifoams">Antifoams</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Biocides">Biocides</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Boiler_water_chemicals">Boiler water chemicals</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Coagulants">Coagulants</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Corrosion_inhibitors">Corrosion inhibitors</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Disinfectants">Disinfectants</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Flocculants">Flocculants</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />

                <a href="#Neutralizing_agents">Neutralizing agents</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />

                <a href="#Oxidants">Oxidants</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Oxygen_scavengers">Oxygen scavengers</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#pH_conditioners">pH conditioners</a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Resin_cleaners"> Resin cleaners </a>
              </u>
              <u className="col-4 fs-6"
              style={{
                fontWeight: "400",
              }}>
                <i className="fa fa-check-circle site-text-primary me-2" />
                <a href="#Scale_inhibitors"> Scale inhibitors </a>
              </u>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Algaecides">Algaecides</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Algaecides are chemicals that kill algae and blue or green
                algae, when they are added to water. Examples are copper
                sulphate, iron salts, rosin amine salts and benzalkonium
                chloride. Algaecides are effective against algae, but are not
                very usable for algal blooms for environmental reasons. The
                problem with most algaecides is that they kill all present
                algae, but they do not remove the toxins that are released by
                the algae prior to death.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Antifoams">Antifoams</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Foam is a mass of bubbles created when certain types of gas are
                dispersed into a liquid. Strong films of liquid than surround
                the bubbles, forming large volumes of non-productive foam.
                <br />
                <br /> The cause of foam is a complicated study in physical
                chemistry, but we already know that its existence presents
                serious problems in both the operation of industrial processes
                and the quality of finished products. When it is not held under
                control, foam can reduce the capacity of equipment and increase
                the duration and costs of processes.
                <br />
                <br /> Antifoam blends contain oils combined with small amounts
                of silica. They break down foam thanks to two of silicone's
                properties: incompatibility with aqueous systems and ease of
                spreading. Antifoam compounds are available either as powder or
                as an emulsion of the pure product.
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Powder</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Antifoam powder covers a group of products based on modified
                polydimethylsiloxane. The products vary in their basic
                properties, but as a group they introduce excellent antifoaming
                in a wide range of applications and conditions. The antifoams
                are chemically inert and do not react with the medium that is
                defoamed. They are odourless, tasteless, non-volatile, non-toxic
                and they do not corrode materials. The only disadvantage of the
                powdery product is that it cannot be used in watery solutions.
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Emulsions</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Antifoam Emulsions are aqueous emulsions of polydimethylsiloxane
                fluids. They have the same properties as the powder form, the
                only difference is that they can also be applied in watery
                solutions.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Biocides">Biocides</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                See disinfectants
                <br />
                Detailed information on biocides is also available here
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Boiler_water_chemicals">Boiler water chemicals</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Boiler water chemicals include all chemicals that are used for
                the following applications: <br />
                <br />
                . &nbsp; Oxygen scavenging.
                <br /> . &nbsp; Scale inhibition.
                <br /> . &nbsp; Corrosion inhibition.
                <br /> . &nbsp; Antifoaming.
                <br /> . &nbsp; Alkalinity control.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Coagulants">Coagulants</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                When referring to coagulants, positive ions with high valence
                are preferred. Generally aluminium and iron are applied,
                aluminium as Al2(SO4)3- (aluin) and iron as either FeCl3 or
                Fe2(SO4)3-. One can also apply the relatively cheap form FeSO4,
                on condition that it will be oxidised to Fe3+ during aeration.
                <br />
                <br />
                Coagulation is very dependent on the doses of coagulants, the pH
                and colloid concentrations. To adjust pH levels Ca(OH)2 is
                applied as co-flocculent. Doses usually vary between 10 and 90
                mg Fe3+/ L, but when salts are present a higher dose needs to be
                applied
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Corrosion_inhibitors">Corrosion inhibitors</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6"
              style={{
                fontWeight: "400",
              }}>
                <p className="fs-6"
              style={{
                fontWeight: "400",
              }}>   Corrosion is a general term that indicates the conversion of a
                metal into a soluble compound.
                <br />
                <br />
                Corrosion can lead to failure of critical parts of boiler
                systems, deposition of corrosion products in critical heat
                exchange areas, and overall efficiency loss. That is why
                corrosion inhibitors are often applied. Inhibitors are chemicals
                that react with a metallic surface, giving the surface a certain
                level of protection. Inhibitors often work by adsorbing
                themselves on the metallic surface, protecting the metallic
                surface by forming a film.</p>  <br />
               There are five different kinds of corrosion inhibitors. These
                are:
                <br />  <br />
                1) &nbsp;&nbsp;Passivity inhibitors (passivators). These cause a shift of
                the corrosion potential, forcing the metallic surface into the
                passive range. Examples of passivity inhibitors are oxidizing
                anions, such as chromate, nitrite and nitrate and non-oxidizing
                ions such as phosphate and molybdate. These inhibitors are the
                most effective and consequently the most widely used.
                <br /><br />
                2) &nbsp;&nbsp;Cathodic inhibitors. Some cathodic inhibitors, such as
                compounds of arsenic and antimony, work by making the
                recombination and discharge of hydrogen more difficult. Other
                cathodic inhibitors, ions such as calcium, zinc or magnesium,
                may be precipitated as oxides to form a protective layer on the
                metal
                <br /><br />
                3) &nbsp;&nbsp;Organic inhibitors. These affect the entire surface of a
                corroding metal when present in certain concentration. Organic
                inhibitors protect the metal by forming a hydrophobic film on
                the metal surface. Organic inhibitors will be adsorbed according
                to the ionic charge of the inhibitor and the charge on the
                surface.
                <br /><br />
                4) &nbsp;&nbsp;Precipitation inducing inhibitors. These are compounds that
                cause the formation of precipitates on the surface of the metal,
                thereby providing a protective film.
                <br /><br />
                The most common inhibitors of this category are silicates and
                phosphates.
                <br /><br />
                5) &nbsp;&nbsp;Volatile Corrosion Inhibitors (VCI). These are compounds
                transported in a closed environment to the site of corrosion by
                volatilisation from a source. Examples are morpholine and
                hydrazine and volatile solids such as salts of
                dicyclohexylamine, cyclohexylamine and hexamethylene-amine. On
                contact with the metal surface, the vapour of these salts
                condenses and is hydrolysed by moist, to liberate protective
                ions.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Disinfectants">Disinfectants</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6"
              style={{
                fontWeight: "400",
              }}>
                Disinfectants kill present unwanted microrganisms in water.
                There are various different types of disinfectants:<br />
               · Chlorine (dose 2-10 mg/L)
                <br />· Chlorine dioxide
                <br />· Ozone
                <br />· Hypochlorite
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Chlorine dioxide disinfection</em>
              <p className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}>
                ClO2 is used principally as a primary disinfectant for surface
                waters with odor and taste problems. It is an effective biocide
                at concentrations as low as 0.1 ppm and over a wide pH range.
                ClO2 penetrates the bacterial cell wall and reacts with vital
                amino acids in the cytoplasm of the cell to kill the organisms.
                The by-product of this reaction is chlorite.
                <br /> <br /> Chlorine dioxide disinfects according to the same
                principle as chlorine, however, as opposed to chlorine, chlorine
                dioxide has no harmful effects on human health.
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Hypochlorite disinfection</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Hypochlorite is aplied in the same way as chlorine dioxide and
                chlorine. Hypo chlorination is a disinfection method that is not
                used widely anymore, since an environmental agency proved that
                the Hypochlorite for disinfection in water was the cause of
                bromate consistence in water.
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Ozone disinfection</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Ozone is a very strong oxidation medium, with a remarkably short
                life span. It consists of oxygen molecules with an extra O-atom,
                to form O3. When ozone comes in contact with odour, bacteria or
                viruses the extra O-atom breaks them down directly, by means of
                oxidation. The third O-atom of the ozone molecules is than lost
                and only oxygen will remain.
                <br />
                Disinfectants can be used in various industries. Ozone is used
                in the pharmaceutical industry, for drinking water preparation,
                for treatment of process water, for preparation of ultra-pure
                water and for surface disinfection. Chlorine dioxide is used
                primarily for drinking water preparation and disinfection of
                piping.
                <br />
                Every disinfection technique has its specific advantages and its
                own application area. In the table below some of the advantages
                and disadvantages are shown:
              </p>

              <table class="table table-bordered border-primary" border="1">
                <tbody>
                  <tr className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                    <td valign="top">Technology</td>
                    <td valign="top">Environ- mentally friendly</td>
                    <td valign="top">By products</td>
                    <td valign="top">Effec-tivity</td>
                    <td valign="top">Inves-tment</td>
                    <td valign="top">Opera-tional costs</td>
                    <td valign="top">Fluids</td>
                    <td valign="top">Surfaces</td>
                  </tr>
                  <tr className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                    <td valign="top">
                      <u >Ozone</u>
                    </td>
                    <td valign="top">+</td>
                    <td valign="top">+</td>
                    <td valign="top">++</td>
                    <td valign="top">-</td>
                    <td valign="top">+</td>
                    <td valign="top">++</td>
                    <td valign="top">++</td>
                  </tr>
                  <tr className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                    <td valign="top">
                      <u >UV</u>
                    </td>
                    <td valign="top">++</td>
                    <td valign="top">++</td>
                    <td valign="top">+</td>
                    <td valign="top">+/-</td>
                    <td valign="top">++</td>
                    <td valign="top">+</td>
                    <td valign="top">++</td>
                  </tr>
                  <tr className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                    <td valign="top">
                      <u >Chlorine dioxide</u>
                    </td>
                    <td valign="top">+/-</td>
                    <td valign="top">+/-</td>
                    <td valign="top">++</td>
                    <td valign="top">++</td>
                    <td valign="top">+</td>
                    <td valign="top">++</td>
                    <td valign="top">--</td>
                  </tr>
                  <tr className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                    <td valign="top">
                      <u >Chlorine gas</u>
                    </td>
                    <td valign="top">--</td>
                    <td valign="top">--</td>
                    <td valign="top">-</td>
                    <td valign="top">+</td>
                    <td valign="top">++</td>
                    <td valign="top">+/-</td>
                    <td valign="top">--</td>
                  </tr>
                  <tr className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                    <td valign="top">
                      <u >Hypochlorite</u>
                    </td>
                    <td valign="top">--</td>
                    <td valign="top">--</td>
                    <td valign="top">-</td>
                    <td valign="top">+</td>
                    <td valign="top">++</td>
                    <td valign="top">+/-</td>
                    <td valign="top">--</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Flocculants">Flocculants</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                To promote the formation of flocs in water that contains
                suspended solids polymer flocculants (polyelectrolytes) are
                applied to promote bonds formation between particles. These
                polymers have a very specific effect, dependent upon their
                charges, their molar weight and their molecular degree of
                ramification. The polymers are water-soluble and their molar
                weight varies between 105 and 106 g/ mol.
                <br />
                <br />
                There can be several charges on one flocculent. There are
                cationic polymers, based on nitrogen, anionic polymers, based on
                carboxylate ions and polyampholytes, which carry both positive
                and negative charges.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Neutralizing_agents">
                Neutralizing agents (alkalinity control)
              </a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                In order to neutralize acids and basics we use either sodium
                hydroxide solution (NaOH), calcium carbonate, or lime suspension
                (Ca(OH)2) to increase pH levels. We use diluted sulphuric acid
                (H2SO4) or diluted hydrochloric acid (HCl) to decline pH levels.
                The dose of neutralizing agents depends upon the pH of the water
                in a reaction basin. Neutralization reactions cause a rise in
                temperature.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Oxidants">Oxidants</a>
            </span>
            <div>
              {/* <span className="c
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}ard-title">Special title treatment</span> */}
              <p className="fs-6 m-t20"
              style={{
                fontWeight: "400",
              }}>
                Chemical oxidation processes use (chemical) oxidants to reduce
                COD/BOD levels, and to remove both organic and oxidisable
                inorganic components. The processes can completely oxidise
                organic materials to carbon dioxide and water, although it is
                often not necessary to operate the processes to this level of
                treatment
                </p>
                <br />
                <p className="fs-6"
              style={{
                fontWeight: "400",
              }}> A wide variety of oxidation chemicals are available. Examples
                are: 
              
                . &nbsp; Hydrogen peroxide;
                <br /> . &nbsp; Ozone.
                <br /> . &nbsp; Combined ozone & peroxide.
                <br /> . &nbsp; Oxygen.</p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Hydrogen peroxide</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Hydrogen peroxide is widely used thanks to its properties; it is
                a safe, effective, powerful and versatile oxidant. The main
                applications of H2O2 are oxidation to aid odour control and
                corrosion control, organic oxidation, metal oxidation and
                toxicity oxidation. The most difficult pollutants to oxidize may
                require H2O2 to be activated with catalysts such as iron,
                copper, manganese or other transition metal compounds.
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Ozone</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Ozone cannot only be applied as a disinfectant; it can also aid
                the removal of contaminants from water by means of oxidation.
                Ozone then purifies water by breaking up organic contaminants
                and converting inorganic contaminants to an insoluble form that
                can then be filtered out. The Ozone system can remove up to
                twenty-five contaminants.
                <br /></p>
              <p className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}>  Chemicals that can be oxidized with ozone are:
                
                <br /><br />   . &nbsp; Absorbable organic halogens.
                <br /> . &nbsp; Nitrite.
                <br /> . &nbsp; Iron.
                <br /> . &nbsp; Manganese.
                <br /> . &nbsp; Cyanide.
                <br /> . &nbsp; Pesticides.
                <br /> . &nbsp; Nitrogen oxides.
                <br /> . &nbsp; Odorous substances.
                <br /> . &nbsp; Chlorinated hydrocarbons.
                <br /> . &nbsp; PCB's.
              </p>
              <em className="fs-5"
              style={{
                fontWeight: "400",
              }}>Oxygen</em>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Oxygen can also be applied as an oxidant, for instance to
                realize the oxidation of iron and manganese. The reactions that
                occur during oxidation by oxygen are usually quite similar.
                <br />
                These are the reactions of the oxidation of iron and manganese
                with oxygen:
                <br />2 Fe2+ + O2 + 2 OH- -{">"} Fe2O3 + H2O
                <br />2 Mn2+ + O2 + 4 OH- -{">"} 2 MnO2 + 2 H2O
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Oxygen_scavengers">Oxygen scavengers</a>
            </span>
            <div>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Oxygen scavenging means preventing oxygen from introducing
                oxidation reactions. Most of the naturally occurring organics
                have a slightly negative charge. Due to that they can absorb
                oxygen molecules, because these carry a slightly positive
                charge, to prevent oxidation reactions from taking place in
                water and other liquids.
                <br />
                <br />
                Oxygen scavengers include both volatile products, such as
                hydrazine (N2H4) or other organic products like carbohydrazine,
                hydroquinone, diethylhydroxyethanol, methylethylketoxime, but
                also non-volatile salts, such as sodium sulphite (Na2SO3) and
                other inorganic compounds, or derivatives thereof. The salts
                often contain catalysing compounds to increase the rate of
                reaction with dissolved oxygen, for instance cobalt chloride.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="pH_conditioners">pH conditioners</a>
            </span>
            <div>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Municipal water is often pH-adjusted, in order to prevent
                corrosion from pipes and to prevent dissolution of lead into
                water supplies. During water treatment pH adjustments may also
                be required. The pH is brought up or down through addition of
                basics or acids. An example of lowering the pH is the addition
                of hydrogen chloride, in case of a basic liquid. An example of
                bringing up the pH is the addition of natrium hydroxide, in case
                of an acidic liquid.
                <br />
                <br />
                The pH will be converted to approximately seven to seven and a
                half, after addition of certain concentrations of acids or
                basics. The concentration of the substance and the kind of
                substance that is added, depend upon the necessary decrease or
                increase of the pH.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
              <a name="Resin_cleaners">Resin cleaners</a>
            </span>
            <div>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Ion exchange resins need to be regenerated after application,
                after that, they can be reused. But every time the ion
                exchangers are used serious fouling takes place. The
                contaminants that enter the resins will not be removed through
                regeneration; therefore resins need cleaning with certain
                chemicals.
                <br />
                <br />
                Chemicals that are used are for instance sodium chloride,
                potassium chloride, citric acid and chlorine dioxide.
                <br />
                <br />
                Chlorine dioxide cleansing serves the removal of organic
                contaminants on ion exchange resins. Prior to every cleaning
                treatment resins should be regenerated. After that, in case
                chlorine dioxide is used, 500 ppm of chlorine dioxide in
                solution is passed through the resin bed and oxidises the
                contaminants.
              </p>
            </div>
          </div>

          <div className="my-5">
            <span
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}>
              <a name="Scale_inhibitors">
                {/* <i className="fa fa-check-circle site-text-primary me-2" /> */}
                Scale inhibitors
              </a>
            </span>
            <div>
              <p className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}>
                Scale is the precipitate that forms on surfaces in contact with
                water as a result of the precipitation of normally soluble
                solids that become insoluble as temperature increases. Some
                examples of scale are calcium carbonate, calcium sulphate, and
                calcium silicate.
                <br />
                <br />
                Scale inhibitors are surface-active negatively charged polymers.
                As minerals exceed their solubility's and begin to merge, the
                polymers become attached. The structure for crystallisation is
                disrupted and the formation of scale is prevented. The particles
                of scale combined with the inhibitor will than be dispersed and
                remain in suspension.
                <br />
                <br />
                Examples of scale inhibitors are phosphate esters, phosphoric
                acid and solutions of low molecular weight polyacrylic acid.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default ChemicalPage;
