import React from 'react'

function BannerSupportPage() {
  return (
    <div className="section-full bg-change-section overlay-wraper p-t40 p-b50">
        <div className="container">
          {/* <div className="card my-3"> */}
            {/* <div className="card-body"> */}
        <div className=" my-3">
          <div className="card-body">
              {/* <span className="text-info display-block title-second text-uppercase font-20 font-weight-600 display-block m-b5">
              <i> support</i>
            </span> */}
              <p className="card-text text-dark fs-5">Coming Soon...</p>
              <p className='p-t150'></p>
            {/* </div> */}
          </div>
          </div>
          </div>
        {/* </div> */}
      </div>
  )
}

export default BannerSupportPage
