import React from 'react';

const Loader = () => {
  return (
    <div style={styles.loaderContainer}>
    <img 
      src="assets/media/logos/chemicals_logo2.png" 
      alt="Loading..." 
      style={{ height: 350, width: 350 }}
    />
    {/* <div style={styles.spinner}></div> */}
  </div>
  
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f3f3f3',
  },
  image: {
    width: '100px', // Adjust size
    height: '100px', // Adjust size
    marginBottom: '20px',
  },
  spinner: {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderLeftColor: '#000',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1.1s linear infinite',
  }
};

export default Loader;