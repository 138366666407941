import SectionFooter1 from "../app/sections/common/footer/footer1";
// import SectionHeader1 from "../app/sections/common/header/header1";
import NewSectionHeader from "../app/sections/common/new-header/newHeader";
// import SectionHeader2 from "../app/sections/common/header/header2";
// import SectionHeader3 from "../app/sections/common/header/header3";
import { route } from "./constants";
import banner from "../globals/data/banner.json";

export function showHeader(currentpath) {
  return !(currentpath === route.pages.ERROR404);
}

export function setFooter(currentpath) {
  if (currentpath === route.pages.features.footer.LIGHT) {
    return <SectionFooter1 theme="footer-light" />;
  } else {
    return <SectionFooter1 theme="footer-dark" />;
  }
}

export function setHeader(currentpath) {
  if (currentpath === route.home.HOME1 || currentpath === route.INITIAL) {
    // return <SectionHeader1 />
    return <NewSectionHeader />;
  }
  //    else if (currentpath === route.home.HOME3) {
  //     return <SectionHeader3 />;
  //   }
  else {
    return <NewSectionHeader />;
    // return <SectionHeader2 />;
  }
}

export const setBannerData = (currentpath) => {
  switch (currentpath) {
    case route.home.HOME1:
      return banner.about;
    case route.pages.ABOUT:
      return banner.about;
    case route.pages.CONTACTUS:
      return banner.contactUs;
    case route.pages.knowledge.KNOWLEDGE:
      return banner.knowledge;
    case route.pages.chemical.CHEMICALS:
      return banner.chemicals;
    // case route.pages.sitemap.SITEMAP:
    //   return banner.sitemap;
    case route.pages.SUPPORT:
      return banner.support;
    case route.services.SERVICES:
      return banner.services;
    // ====================== Product Start ==========
    case route.pages.product.BoilerWaterChemicals:
      return banner.BoilerWaterChemicals;
    case route.pages.product.CoolingWaterChemicals:
      return banner.CoolingWaterChemicals;
    case route.pages.product.SideTreatments:
      return banner.SideTreatments;
    case route.pages.product.RoAntiScalantKits:
      return banner.RoAntiScalantKits;
    case route.pages.product.TestKits:
      return banner.TestKits;
    // case route.pages.product.ParerSlimicides:
    //   return banner.ParerSlimicides;
    // case route.pages.product.SugarProcessing:
    //   return banner.SugarProcessing;
    // case route.pages.product.Biotechnology:
    //   return banner.Biotechnology;
    case route.pages.product.DescalingChemicals:
      return banner.DescalingChemicals;
    case route.pages.product.ChillingWaterChemicals:
      return banner.ChillingWaterChemicals;
    // ====================== Product End ==========
    case route.pages.product.PRODUCTS:
      return banner.Products;
    case route.pages.features.footer.FIXED:
      return banner.footerFixed;
    case route.pages.features.footer.LIGHT:
      return banner.footerLight;
    case route.pages.features.footer.DARK:
      return banner.footerDark;
    // case route.pages.FAQ:
    //   return banner.faq;
    // case route.pages.ERROR404:
    //   return banner.error404;
    // case route.pages.team.TEAM:
    //   return banner.team;
    // case route.pages.team.DETAIL:
    //   return banner.teamDetail;
    // case route.pages.contact.CONTACT1:
    //   return banner.contact1;
    // case route.pages.contact.CONTACT2:
    //   return banner.contact2;
    // case route.pages.gallery.GALLERY1:
    //   return banner.gallery1;
    // case route.pages.gallery.GALLERY2:
    //   return banner.gallery2;
    // case route.services.DETAIL:
    //   return banner.serviceDetail;
    // case route.blog.LIST:
    //   return banner.blogList;
    // case route.blog.LIST_SIDEBAR:
    //   return banner.blogListSidebar;
    // case route.blog.GRID:
    //   return banner.blogGrid;
    // case route.blog.GRID_SIDEBAR:
    //   return banner.blogGridSidebar;
    // case route.blog.POST_SINGLE:
    //   return banner.blogPostSingle;
    // case route.shop.PRODUCTS:
    //   return banner.shopProducts;
    // case route.shop.DETAIL:
    //   return banner.shopProductDetail;
    // case route.shop.CART:
    //   return banner.shopCart;
    // case route.shop.WISHLIST:
    //   return banner.shopWishlist;
    // case route.shop.CHECKOUT:
    //   return banner.shopCheckout;
    // case route.ELEMENTS:
    //   return banner.elements;
    default:
      // return banner.about;
      return banner.error404;
  }
};
