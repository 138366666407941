import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function CoolingWaterChemical() {
  return (
    <div className="section-full  p-t80 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
      <div className="container">
        {" "}
        <div className="my-5">
          <p
            className=""
            style={{
              fontWeight: "460",
              fontSize: 24,
            }}
          >
            Cooling Water Chemicals
          </p>
          <div className="ms-3">
            <p name="MAIN" id="MAIN" style={{
                fontWeight: "400",
              }} className="fs-6">
              There are three sources of water available.
              <br />
              1. Sea Water.
              <br />
              2. Surface water.
              <br />
              3. Under ground water.
            </p>
          </div>
        </div>
        <div className=" my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              INTRODUCTION{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Every industry need water for various purposes like process,
                boilers, for cooling purposes etc.,
              </li>
              <li>
                Water is sufficiently available, economical and has more
                specific heat than any liquid.
              </li>
              <li>Thus water is used for cooling purpose.</li>
              <li>
                But water carries lot of impurities which pose serious threat if
                it is used without proper treatment.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              INTRODUCTION{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                In any cooling process water takes heat from process and cools
                the system.
              </li>
              <li>
                By the way it gets heated up and in turn it need to be cooled to
                reuse the same.
              </li>
              <li>
                It gives up the heat through a process called evaporation.
                <br />
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              TYPE OF COOLING SYSTEMS{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                There are three types of cooling systems which are in use in
                industry:
                <br />
                Once through system
              </li>
              <li>Closed recirculating system</li>
              <li>Open recirculating system</li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              ONCE THROUGH SYSTEM{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Once through systems normally present nearby a sea or river.
              </li>
              <li>
                In this system water is drawn from the source and pass it
                through the system once and discharge it back into the source.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              CLOSED RECIRCULATING SYSTEM{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                In closed recirculating system water is recirculated
                continuously.
              </li>
              <li>
                The heat taken from the process is cooled in an another heat
                exchanger which in turn cooled by cooling tower water.
              </li>
              <li>Thus in other words it is called as a PRIMERY CIRCUIT.</li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              OPEN RECIRCULATING SYSTEM
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                In this system water is recirculated continuously through the
                process where it is required to take heat.
              </li>
              <li>
                The heated water gets cooled in a cooling tower through
                evaporation method.
              </li>
              <li>
                The loss of water through evaporation is compensated with fresh
                makeup water.
              </li>
              <li>
                The open recirculating systems are prone to many external
                contaminations which calls for proper treatment control.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              COOLING WATER TREATMENT
            </p>
            {/* &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>The following problems are encountered in cooling water:</li>
              <li>SCALING</li>
              <li>CORROSIONFOULING</li>
              <li>
                MICRO BIOLOGICAL FOULING
                <br />
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              SCANLIN
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                In a cooling system water is constantly recirculated to remove
                heat from heat exchangers. In this process water is continuously
                evaporated to give up heat, while the minerals it contained are
                left back in the remaining water. This leads to the increase in
                mineral concentration in the cooling water. These minerals start
                precipitating after attaining saturation and form crystalline
                like substance in heat exchanger system. This crystalline growth
                is called Scale.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              CAUSES OF SCALING
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                pH: pH has got a significant effect on scaling. Solubility of
                salts of calcium &amp; magnesium reduces as pH increases. Hence
                they tend to precipitate at higher pH and form scale.
              </li>
              <li>
                Hardness is one of the main elements that induce scale
                formation. <br />
                Higher alkalinity leads to the precipitation of salts of calcium
                &amp; magnesium and also leads to the formation of silicate
                scales.
              </li>
              <li>
                Temperature: Salts of calcium &amp; magnesium have inverse
                solubility i.e., as the temperature increases their solubility
                decreases. Thus they precipitates and form scales.
              </li>
              <li>
                Silica precipitates as Calcium silicate or magnesium silicate at
                higher pH. This forms hard scales.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              REMENDIAL MEASURES TO CONTROL SCALING
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Scaling is control with addition of scale inhibitors which form
                water soluble complexes with calcium &amp; magnesium ions and
                prevent them from precipitating.
              </li>
              <li>
                While adding above chemicals the following parameters to be
                monitored:
              </li>
              <li>Maintain pH at less than 8.5.</li>
              <li>
                Maintain regular blow down as recommended to control build up of
                mineral concentration.
              </li>
              <li>
                Identify low velocity areas in cooling system and correct the
                same. <br />
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              CAUSES OF CORROSION{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Corrosion is nothing but physical loss of metal when the
                <br />
                metal turns to it&rsquo;s oxide form. It occurs due to the
                following:
              </li>
              <li>Low pH</li>
              <li>
                Presence of oxygen. Oxygen combines with electrons and water at
                cathode. Continuous contact of cathode with oxygen keeps
                corrosion process going on by way of inducing electron flow from
                anode and this results in loss of metal ai anodic sites.
              </li>
              <li>
                Presence of Corrosive anions like Chlorides, sulfates, etc.,
                <br />
                Presence of Bacteria viz., Sulfate reducing bacteria, Nitrifying
                bacteria, iron reducing bacteria.
              </li>
              <li>Dissimilar metals in the system cause galvanic corrosion.</li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <br />
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              PROBLEMS DUE TO CORRESION &nbsp;
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Reduces heat transfer due to build up of corrosion products in
                the heat exchanger.
              </li>
              <li>
                Corrosion increases maintenance costs as heat exchangers
                frequently fouled by corrosion products.
              </li>
              <li>
                Corrosion increases repairs and replacement costs and in turn it
                increases down time losses.
              </li>
              <li>
                Corrosion develops leaks in heat exchanger system due to which
                cooling water gets mixed up with process fluids and contaminate
                the process.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              REMEDIAL MEASURES TO CONTROL CORROSION &nbsp;
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Passivate anodic sites to prevent electron transfer with the
                help of anodic inhibitors. Similarly passivate cathodic sites
                with the help of cathodic inhibitors also.
              </li>
              <li>
                Use of filming inhibitors suitable to the metallurgy of the
                system to control corrosion.
              </li>
              <li>
                Use of suitable biocides to control corrosion causing bacteria.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <br />
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              FOULING{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Foulants are particles of oil, dust, silt, dead organisms, scale
                &amp; corrosion products and other debris in suspended state in
                cooling water. They enter the system through internal and
                external sources.
              </li>
              <li>
                These foulants stick together and gradually settle on metal
                surfaces of the cooling lines and create deposites.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              PROBLEMS OF FOULING
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>Obstructs water flow.</li>
              <li>Reduces heat transfer efficiency.</li>
              <li>
                Reduces the performance of scale and corrosion inhibitors.
              </li>
              <li>
                Induces corrosion which leads to increased maintenance and
                replacement costs.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">Remedial measures to control fouling</span>
          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>Addition of dispersants help control fouling.</li>
              <li>
                Low velocity areas are to be identified and corrective action is
                to be taken.
              </li>
              <li>
                Remove foulants regularly with the help of side stream
                filteration help control fouling.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              MISCROBIOLOGICAL CONTAMINATION{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>

          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Water is an ideal place for micro organisms to live and breed.
              </li>
              <li>
                Bacteria, algae and fungi are the most common microbes that are
                encountered in cooling water systems.
              </li>
              <li>
                These microbes cause serious damage to the cooling water system.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              TYPES OF BACTERIA{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>

          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Iron reducing bacteria
                <br />
                These bacteria survives by converting iron to iron oxides.
              </li>
              <li>Sulfate reducing bacteria</li>
              <li>
                These bacteria survives by converting sulfates in cooling water
                to sulfites there by reduces cooling water pH.
              </li>
              <li>Nitrifying bacteria</li>
              <li>
                These bacteria survives by converting ammonia to nitrites and
                there by reduces cooling water pH.
              </li>
              <li>Slime forming bacteria</li>
              <li>These bacteria secrets slime in cooling water system.</li>
              <li>
                Apart from the above cooling water is conducive for the growth
                of Algae &amp; Fungi.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <br />
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              PROBLEMS ENCOUNTERED WITH MISCROBES
            </p>
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              {" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>

          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Iron reducers cause iron oxide deposites in heat exchanger
                system. This reduces heat transfer efficiency.
              </li>
              <li>
                Sulfate reducers cause reduction in cooling water pH thereby
                cause severe corrosion in cooling system.
              </li>
              <li>
                Nitrifying bacteria causes reduction in cooling water pH and
                induce severe corrosion.
              </li>
              <li>
                Slime forming bacteria secret slime in cooling system which is
                sticky. This slime entraps suspended particles and fouls the
                system. Also it reduces heat transfer efficiency of heat
                exchangers. It reduces water flow rate in the cooling system.
              </li>
              <li>Algae grows with the help of sun light.</li>
              <li>
                Fungi is produced by microbes that thrives on cellulose. These
                destruct cooling tower wooden structures.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <br />
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              MEASURES TO CONTROL MICROBIAL PROBLEMS{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>

          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                Control entrainment of process contamination like phosphates,
                ammonia etc.,.
              </li>
              <li>
                While designing phosphate corrosion inhibitor program care
                should be taken to see that phosphate reserves are not exceeding
                10ppm.
              </li>
              <li>
                Recommend non &ndash; oxidising biocides compatible to the
                dispersants.
              </li>
              <li>
                pH is one of the important factors which enhances the
                performance of biocides.
              </li>
              <li>
                Select effective combination of Oxidizing and non &ndash;
                oxidizing program to suit the system&rsquo;s operating condition
                and other scale &amp; corrosion programs.
              </li>
              <li>
                Supplement the biocide program with <br />
                bio - dispersants to improve the efficacy of biocides.
              </li>
            </ul>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <br />
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              COOLING WATER CALCULATIONS EVAPORATION{" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>

          <div className="ms-3">
            <p
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
             0.1%
              evaporation takes place for every <br />
             10 F
              rise in temperature.
              <br />
             Evaporation
              rate (M3/Day) = 0.001x Rec. Rate x &#8710;T 0F x 1.8 x 24 Hrs
              <br />
             Blow
              Down (M3/Day)= evaporation/C &ndash; 1<br />
             C =
              Cycle of concentrations.
              <br />
            </p>
          </div>
        </div>
        <div className="my-5">
          <span className="">
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              LANGLIER INDEX
            </p>
            <p
              style={{
                fontWeight: "460",
                fontSize: 20,
              }}
            >
              {" "}
            </p>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ] */}
          </span>

          <div className="ms-3">
            <ul
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <li>
                This helps us to find out tendency of cooling water, i.e.,
                whether the water is on scaling side or corrosive side.
              </li>
              <li>
                With the help of the table we first evaluate saturation pH.
                Saturation pH is the pH at which caco3 becomes saturated in the
                cooling water.
              </li>
              <li>L.I = pH &ndash; pHs pH = analytical pH.</li>
              <li>pHs = saturation pH</li>
              <li>
                If LI is on +ve side indicates Scaling. If LI is on - ve side
                indicates corrosion. If LI is 0.0 indicates chemically balanced
                and prone to pitting.
              </li>
              <li>
                The optimum LI range is between + 0.5 &amp; + 1.0.
                <br />
                Evaluation of Corrosion Rate
                <br />
              </li>
            </ul>
            <span
              className=" ms-3 fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              <p
                style={{
                  fontWeight: "460",
                  fontSize: 20,
                }}
              >
                {" "}
              </p>
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Corrosion
              Rate = W1 &ndash; W2 x534
              <br />
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;&nbsp;&nbsp;<strong> </strong>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong> </strong>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;---------------- MPY
              <br />
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;<strong> </strong>&nbsp;&nbsp;D x A x T
              <br />
              <strong> </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong> </strong>&nbsp;&nbsp;&nbsp;MPY = mills per year.
            </span>

            <div className="">
              <p
                className="  fs-6 m-t30"
                style={{
                  fontWeight: "400",
                }}
              >
              
               W1 = Initial Weight of Coupon in mg.
                <br />
              
               W2 = Final weight of Coupon in mg.
                <br />
              
               D = Density of Coupon
                <br />
              
               A = Surface area of Coupon in in2.
                <br />
              
               T = Time of exposure in Hours.
                <br />
              
               The minimum time of exposure should be 30
                days.
              </p>
            </div>
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
             
            Standard corrosion rate allowed is 3 to 5 mpy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoolingWaterChemical;
